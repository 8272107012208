<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          :fields="[
            //'id',
            helpers.monthName,
            { label: 'Combination', ...getHelperObject('invoice_format_combination') },
            { label: 'Format', ...getHelperObject('invoice_format_marker') },
            { key: 'output', helper: { id: 'output_id', parser: id => parsers.readHelper(id, 'output_types', 'name') } },
            { ...helpers.validIcons, key: 'payment_receipt' },
            helpers.createdAt
          ]"
          :config="{
            url: 'admin/invoice_formats',
            route: '/admin/parameters/invoice_formats',
            name: 'invoice format',
            params: { _lists: 'months,output_types,invoice_format_markers,invoice_format_combinations,invoice_templates' },
            duplicate: true,
            latest: 'month_id'
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'InvoiceFormats',
  mixins: [crudMixin]
}
</script>
