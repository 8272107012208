var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{attrs:{"fields":[
          //'id',
          _vm.helpers.monthName,
          Object.assign({}, {label: 'Combination'}, _vm.getHelperObject('invoice_format_combination')),
          Object.assign({}, {label: 'Format'}, _vm.getHelperObject('invoice_format_marker')),
          { key: 'output', helper: { id: 'output_id', parser: function (id) { return _vm.parsers.readHelper(id, 'output_types', 'name'); } } },
          Object.assign({}, _vm.helpers.validIcons, {key: 'payment_receipt'}),
          _vm.helpers.createdAt
        ],"config":{
          url: 'admin/invoice_formats',
          route: '/admin/parameters/invoice_formats',
          name: 'invoice format',
          params: { _lists: 'months,output_types,invoice_format_markers,invoice_format_combinations,invoice_templates' },
          duplicate: true,
          latest: 'month_id'
        }},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }